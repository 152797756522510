<div class="main-container">
  <ng-container *ngIf="ro; else loading">
    <header class="details-header">
      <div class="header-left">
        <h1 class="header-title">{{'ro-details.header.title'|translate}}</h1>
        <p class="order-creation-date" *ngIf="ro.createdOn">{{'common.creation-date:'|translate}} {{ro.createdOn|date}}
          {{'common.at'|translate}}
          {{ro.createdOn|time}}
        </p>
      </div>
      <div class="header-right">
        <p>{{'common.status:'|translate}} <strong>{{'ro-sap-status.' + ro.replenishmentSAPStatus|
            translate}}</strong></p>
      </div>
    </header>

    <p-table *ngIf="requests" [value]="requests" responsiveLayout="scroll" sortField="designation"
      rowGroupMode="rowspan" groupRowsBy="order.orderExternalIdentifier">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'product.productJsonDataM2.name.labels.' + langFull">
            {{'ro-details.columns.designation'|translate}}
            <p-sortIcon [field]="'product.productJsonDataM2.name.labels.' + langFull">
            </p-sortIcon>
          </th>
          <th>{{'ro-details.columns.sku'|translate}}</th>
          <th>{{'ro-details.columns.qty'|translate}}</th>
          <th></th>
          <th pSortableColumn="order.orderExternalIdentifier">{{'ro-details.columns.order'|translate}}
            <p-sortIcon field="order.orderExternalIdentifier">
            </p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rr let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr [class.group-end]="!rowspan">
          <td>
            {{rr.product.productJsonDataM2.name?.labels | i18nLabel}}
          </td>
          <td>
            {{rr.productSku}}
          </td>
          <td class="ro-details-qty">
            <ng-container *ngIf="!isLocked">
              <p-button icon="pi pi-minus" styleClass="p-button-sm" [disabledInput]="rr.quantity <= 1"
                appPreventDoubleClick
                (debounceClick)="decrement(rr)"></p-button>
              {{rr.quantity}}
              <p-button icon="pi pi-plus" styleClass="p-button-sm" appPreventDoubleClick (debounceClick)="increment(rr)"></p-button>
            </ng-container>
            <ng-container *ngIf="isLocked">
              {{rr.quantity}}
            </ng-container>
          </td>
          <!-- cancel / trash / delete -->
          <td>
            <button *ngIf="!isLocked" pButton type="button" appPreventDoubleClick (debounceClick)="deleteItem(rr)"
              [label]="'ro-details.columns.delete'|translate"></button>
          </td>
          <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
            <a class="link" [routerLink]="['/order/', rr.order.orderId, 'prepare' ]">{{rr.order.orderExternalIdentifier}}</a>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </ng-container>

  <footer class="details-footer">
    <button pButton type="button" class="p-button-outlined button-quit"
      (click)="back()">{{'ctas.quit'|translate}}</button>
  </footer>
</div>
<ng-template #loading>
  <app-full-page-spinner></app-full-page-spinner>
</ng-template>
